/* Asegúrate de que los estilos se aplican correctamente al modal y su contenido */
.custom-modal .modal-dialog {
    max-width: 90% !important;
    width: auto !important;
}

.custom-modal .modal-content {
    background-color: #333 !important; /* Color de fondo oscuro para el modal */
    color: #fff !important; /* Color de texto claro para el modal */
    border-radius: 10px !important;
}

.custom-modal .modal-header,
.custom-modal .modal-footer {
    border: none !important;
}

.custom-modal .modal-body {
    padding: 20px !important;
}

.custom-modal-footer {
    border-top: none !important;
}
/* Estilos de la tabla */
.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #fff;
    background-color: #343a40; /* Cambia el color de fondo de la tabla */
    border-color: #454d55; /* Cambia el color de borde de la tabla */
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #454d55;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #454d55;
}

.table tbody + tbody {
    border-top: 2px solid #454d55;
}

.table .table {
    background-color: #343a40;
}