.main-layout-container {
    display: flex;
    height: 100vh; /* Asegura que ocupe toda la altura de la ventana */
}

.sidebar {
    background-color: #2d3748;
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 16rem; /* Ancho fijo para la barra lateral */
    position: fixed; /* Fija la barra lateral */
    height: 100%; /* Ocupa toda la altura */
}

.logo-container {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}

.logo {
    width: 100px;
    height: auto;
}

.sidebar-list {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}

.sidebar-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease;
}

.sidebar-item:hover {
    background-color: #4a5568;
}

.sidebar-item.active {
    background-color: #4a5568;
}

.content {
    background-color:  #2d3748;
    margin-left: 16rem; /* Deja espacio para la barra lateral fija */
    overflow-y: auto; /* Permite que el contenido se desplace */
    flex-grow: 1; /* Asegura que el contenido ocupe todo el espacio disponible */
    height: 100vh; /* Asegura que el contenido ocupe toda la altura de la ventana */
}

/* Estilos para el RouteLayout*/

.route-layout {
    border: 1px solid #4a5568; /* Agrega un borde alrededor del layout */
    border-radius: 8px; /* Opcional, para bordes redondeados */
    background-color: #1a202c;
    margin: 1rem;
    padding: 1rem;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.route-layout-container {
    background-color: #2d3748;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    flex-grow: 1;
}

.route-layout-header {
    align-items: center;
    border-bottom: 1px solid #4a5568;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.header-title {
    color: #e2e8f0;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.header-title .relative {
    position: relative;
}

.header-title .relative .absolute {
    bottom: 0;
    right: 0;
    background-color: #1a202c;
    border-radius: 50%;
    padding: 0.25rem;
}

.route-layout-tabs {
    border-bottom: 1px solid #4a5568;
    display: flex;
    overflow-x: auto;
    padding: 0.5rem 0;
}

.tab-item {
    cursor: pointer;
    padding: 0.75rem 1rem;
    text-align: center;
}

.tab-item:hover {
    color: #a0aec0;
}

.tab-item.text-white {
    border-bottom: 2px solid #e2e8f0;
    color: #e2e8f0;
}

.route-layout-content {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.add-button-container {
    position: absolute;
    top: 20px;
    right: 20px;
}

.add-button {
    display: inline-flex;
    align-items: center;
    background-color: #009879;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    float: right;
}

.add-button:hover {
    background-color: #007f67;
}